<style>
table.v-table thead th {
    font-size: 30px !important;
 }

table.v-table tbody td {
    font-size: 18px !important;
}

.liveResult {
  background-color: #87D37C
}
</style>

<template>
  <v-app>
    <v-app-bar
      dense
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        Premeta Mychip
      </div>

      <v-spacer></v-spacer>

      <v-switch v-model="$vuetify.theme.dark" label="Dark theme"></v-switch>

      <button @click.prevent="playSound()">Play Sound</button>

      <v-chip small class="ma-2" :color="connected_socket?'green':'red'">Socket</v-chip>
      <v-chip small class="ma-2" :color="connected_resultats?'green':'red'">Resultats</v-chip>
      <v-chip small class="ma-2" :color="connected_maleta?'green':'red'">Maleta</v-chip>
      
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>

      <!-- Provides the application the proper gutter -->
      <v-container fluid>

        <v-expansion-panels>
          <v-expansion-panel>
           <v-expansion-panel-header>
            Configuració
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col>
                <v-text-field filled label="Websocket server" v-model="config.websocket_server"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field filled label="Resultats URL" v-model="config.results_url"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field filled label="Resultats ID" v-model="config.results_id"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field filled label="Participants a mostrar" v-model="config.show_participants" type="number"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field filled label="Pre-meta posició (m)" v-model="config.pre_finish_line_position"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field filled label="Meta posició (m)" v-model="config.finish_line_position"></v-text-field>
              </v-col>
            </v-row>
            
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-row class="mt-0">
        <v-col v-for="stat, modality, idx in stats">
          <v-card :style='{"border-left": `15px solid ${config.colors[idx]}`}'>
            <v-card-text>
              <div class="text-center">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn :color="config.colors[idx]" icon small class="mr-2" v-bind="attrs" v-on="on">
                      <v-icon small class="fa-solid fa-palette"></v-icon>
                    </v-btn>
                  </template>
                  <v-color-picker v-model="config.colors[idx]" class="ma-2" hide-canvas show-swatches hide-sliders hide-inputs mode="hexa"></v-color-picker>
                </v-menu>
                {{modality}}
              </div>
              <div class="text-center">
                <v-icon small class="ma-2 fa-solid fa-file-pen">{{stat["total"]}}</v-icon>
                <v-icon small class="ma-2 fa-solid fa-circle-play">{{stat["started"]}}</v-icon>
                <v-icon small class="ma-2 fa-solid fa-person-running">{{stat["started"]-stat["finished"]-stat["abandon"]}}</v-icon>
                <v-icon small class="ma-2 fa-solid fa-flag-checkered">{{stat["finished"]}}</v-icon>
                <v-icon small class="ma-2 fa-solid fa-user-slash">{{stat["abandon"]}}</v-icon>
              </div>
              <div class="text-h3 text-center"> 
                <span style="font-family: lcd;">
                {{ moment.utc(moment(timer_now).diff(moment(stat['timer'])), 'x').format("HH:mm:ss") }}
                </span>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-simple-table dense v-if="results" class="mt-2">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">Pos.</th>
              <th class="text-center">Dorsal</th>
              <th class="text-left">Nombre</th>
              <th class="text-center">Pos/Cat.</th>
              <th class="text-center">Tiempo</th>
              <th class="text-center">Llegada</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="result in results.slice(0, config.show_participants)" v-if="result.end_time" :class="!result.finished && 'liveResult'">
              <td class="text-center text-h5" :style='{"border-left": `15px solid ${config.colors[stats[result.modality]["idx"]]}`}'>
                {{ result.pos }}{{ result.pos_gender }}
              </td>
              <td class="text-center">
                <span :style='{"border": `5px solid ${config.colors[stats[result.modality]["idx"]]}`}' class="pa-1 rounded-lg">{{ result.bib }}</span>
              </td>
              <td>
                <span class="text-h5">{{ result.name }}</span>
                <br>
                <span class="text-subtitle-2" v-if="result.team">{{ result.team }}</span>
                <span class="text-subtitle-2" v-else>---</span>
              </td>
              <td class="text-center">
                <span class="text-h5">{{ result.pos_cat }}{{ result.pos_gender }}</span>
                <br>
                <span class="text-subtitle-2">{{ result.category }}</span>
              </td>
              <td class="text-center text-h5">
                {{ result.time_.format("HH:mm:ss") }}
                <span class="text-subtitle-2" v-if="result.time/86400>1">
                  (+{{parseInt(result.time/86400)}}d)
                </span>
              </td>
              <td class="text-center" :style='{"border-right": `15px solid ${config.colors[stats[result.modality]["idx"]]}`}'>
                <v-icon v-if="result.finished" class="ml-2 fa-solid fa-flag-checkered"></v-icon>
                <v-icon v-else color="red" class="ml-2 fa-solid fa-circle fa-beat"></v-icon>
                <br>
                {{result.end_time_.fromNow()}}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      </v-container>
    </v-main>

  </v-app>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
  name: 'App',

  created: function() {
    console.log("Everything created!")
    if ('config' in localStorage) {
      this.$set(this.$data, 'config', JSON.parse(localStorage.config))
      console.log(JSON.parse(localStorage.config))
    }
    this.connect_socket()
    this.get_results()
    setInterval(this.get_results, 3500)

    // clock
    setInterval(() => { this.$data.timer_now = moment() }, 1000)
  },

  data: () => ({
    config: {
      colors: ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6', 
      '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
      '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A', 
      '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
      '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC', 
      '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
      '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680', 
      '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
      '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', 
      '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'],
      websocket_server: "ws://localhost:8000",
      results_url: "http://localhost:8181",
      results_id: 1861,
      show_participants: 20,
      pre_finish_line_position: 99750,
      finish_line_position: 100000
    },
    connected_maleta: false,
    connected_socket: false,
    connected_resultats: false,
    results: null,
    // live_data: [{"bib": 2, "timestamp": 2}],
    live_data: [],
    stats: null,
    timer_now: moment.locale('es'),
    moment: moment,
    bibs_finished: [],
    all_bibs: [],
    soundurl: 'http://soundbible.com/mp3/analog-watch-alarm_daniel-simion.mp3'
  }),

  methods: {
    connect_socket: function() {

      console.log("Trying to connect to socket")

      // Socket server connection
      const socket = new WebSocket(this.$data.config.websocket_server);

      socket.onopen = (event) => {
        console.log("WS connected")
        this.connected_socket = true
      }

      socket.onclose = (event) => {
        this.connected_socket = false
        console.log("WS disconected. Reconecting...")
        setTimeout(this.connect_socket, 5000)
      }

      socket.onerror = (event) => {
        this.connected_socket = false
        console.log("some error occurred: ", event)
        socket.close()
      }

      socket.onmessage = (event) => { this.parse_socket_message(event.data) }

    },
    parse_socket_message: function(msg) {
      console.log("Rcvd: ", msg)

      msg = JSON.parse(msg)

      if ( !Array.isArray(msg) ) {
        console.log("Converting to Array")
        msg = [msg]
      }

      msg.forEach( (msg) => {
        if ( msg.from == 'sender' ) {
          if ( 'bib' in msg && 'time' in msg ) {
            if ( this.$data.all_bibs.includes(msg['bib']) ) {
              if ( !this.$data.bibs_finished.includes(msg['bib']) ) {
                this.$data.live_data.push({
                  "bib": msg.bib,
                  "time": msg.time
                })
              }
            }
          }
        }
      })

      this.parse_live_data(this.$data.results)
      
    },
    get_results: function() {
      axios
        .get(`${this.$data.config.results_url}/datos${this.$data.config.results_id}.json`)
        .then( response => {
          this.$data.connected_resultats = true
          this.parse_results(response)
        })
        .catch( err => {
          this.$data.connected_resultats = false
          this.results = null
          this.stats = null
          console.log("Error", err)
        })
    },
    parse_results: function(res) {
      const DORSAL = 0
      const NOMBRE = 1
      const POS = 2
      const CAT = 3
      const TEAM = 34
      const POS_CAT = 5
      const TIME = 6
      const MODALIDAD = 31
      const SEXO = 35
      const START_TIME = 33
      const START_TIME_AUTO = 32

      let results = []
      let bibs_finished = []
      let all_bibs = []
      let stats = {}
      res.data.clasificacion.datos.forEach( (p) => {

        let bib = p[DORSAL]
        all_bibs.push(bib)
        
        let time = 0
        let time_ = p[TIME]
        if ( time_.length > 0 ) {
          time_ = time_.replace("h", ":").replace("m", ":").replace("s", "").split(":")
          time = parseInt(time_[0]) * 3600 + parseInt(time_[1]) * 60 + parseInt(time_[2])
        }

        let finished = time > 0
        if ( finished ) bibs_finished.push(bib)

        let start_time = 0
        let start_time_ = moment(p[START_TIME_AUTO])
        start_time = start_time_ / 1000

        let end_time = 0
        if ( start_time > 0 && time > 0 ) {
          end_time = start_time + time
        }

        let gender = p[SEXO]

        let started = start_time > 0

        let modality = p[MODALIDAD]
        let category = p[CAT]
        let pos = p[POS]
        let pos_cat = p[POS_CAT]

        results.push(
          {
            "bib": bib,
            "name": p[NOMBRE],
            "pos": pos,
            "pos_cat": pos_cat,
            "category": category,
            "team": p[TEAM],
            "time": time,
            "time_": moment.utc(time, 'X'),
            "start_time": start_time,
            "end_time": end_time,
            "end_time_": moment.utc(end_time, 'X'),
            "modality": modality,
            "gender": gender,
            "pos_gender": (gender == 'M') ? 'º' : 'ª',
            "finished": finished,
            "started": started
          }
        )

        if ( !(modality in stats) ) {
          stats[modality] = {
            "idx": Object.keys(stats).length,
            "total": 0,
            "started": 0,
            "finished": 0,
            "abandon": 0,
            "timer": 0,
            "positions": {
              "gender": {},
              "category": {}
            }
          }
        }

        if ( start_time_ > stats[modality]['timer'] ) {
          stats[modality]['timer'] = start_time_
        }

        stats[modality]["total"] += 1
        if ( finished ) stats[modality]["finished"] += 1
        if ( started ) stats[modality]["started"] += 1

        if ( !(gender in stats[modality]['positions']['gender'] )) {
          stats[modality]['positions']['gender'][gender] = pos
        }

        if ( pos > stats[modality]['positions']['gender'][gender] ) {
          stats[modality]['positions']['gender'][gender] = pos
        }

        if ( !(category in stats[modality]['positions']['category'] )) {
          stats[modality]['positions']['category'][category] = pos_cat
        }

        if ( pos_cat > stats[modality]['positions']['category'][category] ) {
          stats[modality]['positions']['category'][category] = pos_cat
        }
      })

      this.stats = stats
      this.bibs_finished = bibs_finished
      this.all_bibs = all_bibs
      this.parse_live_data(results)
    },
    parse_live_data: function(results) {

      this.$data.live_data.forEach( (ld) => {
        if ( !this.$data.bibs_finished.includes(ld.bib) ) {
          let idx = results.indexOf(results.find(el => el.bib == ld.bib))
          if ( idx >= 0 ) {

            let time = ld.time - results[idx]['start_time']

            let compensed = time * this.$data.config.finish_line_position / this.$data.config.pre_finish_line_position
            
            results[idx]['time'] = compensed
            results[idx]['time_'] = moment.utc(compensed, 'X')
            
            let end_time = results[idx]['start_time'] + compensed
            results[idx]['end_time'] = end_time
            results[idx]['end_time_'] = moment.utc(end_time, 'X')

            let modality = results[idx]['modality']
            let category = results[idx]['category']
            let gender = results[idx]['gender']
            this.stats[modality]['positions']['gender'][gender] += 1
            this.stats[modality]['positions']['category'][category] += 1
            results[idx]['pos'] = this.stats[modality]['positions']['gender'][gender]
            results[idx]['pos_cat'] = this.stats[modality]['positions']['category'][category]
          }
        }
      })

      results.sort( function(a, b) {
        if ( a.end_time == 0 ) return 1
        if ( b.end_time == 0 ) return -1
        if ( a.end_time < b.end_time ) return 1
        else return -1
      })

      this.results = results
    },
    playSound () {
      var audio = new Audio(this.$data.soundurl);
      audio.play();
    }
  },
  watch: {
    config: {
      handler: function (new_config) {
          this.get_results()
          localStorage.config = JSON.stringify(new_config)
      },
      deep: true
    }
  }
};
</script>